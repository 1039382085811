import { Stop } from "@api/graphql/generated/generated-types";

export function findIfShipmentIsMultiDay(
  stops: Stop[],
  shipmentDate?: string
): boolean {
  if (stops.length < 2) {
    return false;
  }

  const firstStopDate = stops[0].stopDate;
  // Check if shipmentDate is different from the first stop date
  if (shipmentDate && firstStopDate && shipmentDate !== firstStopDate) {
    return true;
  }

  let previousDate = firstStopDate;

  for (let i = 1; i < stops.length; i++) {
    const currentStop = stops[i];
    if (
      currentStop.stopDate &&
      previousDate &&
      currentStop.stopDate !== previousDate
    ) {
      return true;
    }
    previousDate = currentStop.stopDate;
  }

  return false;
}
